@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-text-field {
  &.v-text-field--dialog-activator {
    ::v-deep .v-input__control > .v-input__slot > .v-text-field__slot {
      // custom style to replace `v-icon mdi-menu-down`
      background: url("~@/assets/custom-icons/caret-down--dark.svg") no-repeat right center;
      background-size: 0.7rem;

      .theme--light & {
        background-image: url("~@/assets/custom-icons/caret-down--light.svg");
      }
    }
  }
}
// Designers requested the dialog to cover the entire height of the viewport
.v-dialog {
  ::v-deep &:not(.v-dialog--fullscreen) {
    max-height: 96%;
  }
  &__content {
    align-items: flex-start;
    justify-content: left;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.wx-modal-dialog {
  &.v-card {
    &.v-sheet {
      display: flex;
      flex-direction: column;
      background-color: var(--color-element-layer3);

      @media ($wx-isMobile) {
        height: 90vh;
      }
      @media ($wx-isMobilePhone) {
        height: 88vh;
      }
      @media ($wx-isNotMobile) {
        height: 94vh;
      }
    }
  }

  .v-card {
    // static header
    &__title {
      background-color: var(--color-element-layer3);
    }
    // scrollable body
    &__text {
      position: relative;
      z-index: 0;

      .v-list {
        background-color: transparent;
        box-shadow: none;

        .v-list-item__title,
        .v-list-item__content {
          font-size: var(--font-size-norm);
        }
        .v-list-item__title {
          padding-top: 0.75rem;
          padding-bottom: 0.25rem;
          color: var(--color-text-theme);
          font-weight: 700; /* Bold */

          &:not(:first-of-type) {
            margin-top: 0.5rem;
          }
        }
        .v-list-item__content {
          padding: 0.5rem 0;
        }
      }
    }
  }
}
