@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// matching the style of `ui/WxContextualizedHelp.vue`
.wx-contextualized-help {
  &__activator {
    font-size: var(--font-size-h3);
  }
}

.inverted-theme-card {
  &.v-card {
    background-color: var(--color-flat-panel-theme);
    color: var(--color-text-theme);
    opacity: 1;

    .v-card__text {
      color: var(--color-text-theme);
    }
  }
}

::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    position: initial;
    left: auto;
    width: 100%;
    max-width: 500px;
    padding-inline: var(--grid-gutter);
    pointer-events: auto;

    header {
      padding-top: var(--grid-gutter);

      .close-btn {
        position: fixed;
        z-index: 1; // hover fields
        top: var(--dialog-close-offset);
        right: var(--dialog-close-offset);
      }
    }
    .v-form {
      width: 100%;

      fieldset {
        border: none;

        &.form-footer-actions {
          display: flex;
          justify-content: flex-end;
          column-gap: var(--btn-inline-margin);
          padding-bottom: var(--grid-gutter);
        }
      }

      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;
        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
.cf-override-row {
  .inline-fields {
    width: 100%;

    .production-run-properties {
      border: 1px solid var(--color-text-subtle-theme);
      border-bottom-width: 2px;
      border-radius: var(--border-radius-sm);
    }
  }
  padding-bottom: var(--box-padding-admin);

  .inline-fields {
    flex-direction: column;
  }
}
.production-run-navigation-label {
  font-size: var(--font-size-h2);
  line-height: 1.2;
  font-weight: 400;
}
.production-run-property {
  max-width: 475px;
}
